<div id="team">
  <div class="white-text-wrap">
    <div class="gray-text">
      <h1 class="out-view" translate="team"></h1>
      <p class="out-view" translate="teamText">
      </p>
    </div>
  </div>

  <div class="partner-tags-wrap">
    <div class="partner-tags">
      <a (click)="setType(null)" translate="all"></a>
      <a (click)="setType('partner')" translate="partners"></a>
      <a (click)="setType('senior')" translate="seniorAssociates"></a>
      <a (click)="setType('associates')" translate="associates"></a>
      <a (click)="setType('office')" translate="officeManager"></a>
    </div>
  </div>

  <div class="employee-bio" *ngIf="selectedEmployee" (click)="closeDetails()">
    <img [src]="selectedEmployee.imgUrl" [alt]="selectedEmployee.name">
    <div class="employee-data" [class.padding-narrow]="selectedEmployee.name === 'Stefan Savić' ">
      <h4>{{ selectedEmployee.name }}</h4>
      <p>{{ selectedEmployee.title }}</p>

      <div class="label-wrap">
        <label translate="email"></label>
        <p [innerHTML]="selectedEmployee.email"></p>
      </div>

      <div class="label-wrap">
        <label translate="phone"></label>
        <p>{{ selectedEmployee.phone }}</p>
      </div>

      <!-- <div class="label-wrap">
        <label translate="education"></label>
        <p [innerHtml]="selectedEmployee.education"></p>
      </div> -->

      <!-- <div class="label-wrap">
        <label *ngIf="selectedEmployee.barAdmissions" translate="barAdmissions"></label>
        <p>{{ selectedEmployee.barAdmissions }}</p>
      </div> -->

      <div class="label-wrap">
        <label *ngIf="selectedEmployee.practiceAreas" translate="practice"></label>
        <p>{{ selectedEmployee.practiceAreas }}</p>
      </div>

      <p>{{ selectedEmployee.trivia }}</p>
    </div>
  </div>

  <div class="team-wrap">
    <div class="associates" (click)="select(employee, 'associate-' + i)"
      *ngFor="let employee of employees; let i = index" [attr.id]="'associate-' + i">
      <img [src]="employee.imgUrl" [alt]="employee.name">
      <div class="name">
        <p>{{ employee.name }}</p>
        <div class="triangle"></div>
      </div>
      <div [class.grayed]="type && type !== employee.type" *ngIf="!selectedEmployee"></div>
    </div>
  </div>
</div>
