import { Injectable } from '@angular/core';
import { EmployeeModel } from '../models/employee.model';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  // tslint:disable:max-line-length

  private english = [
    {
      name: 'Đorđe Nikolić',
      email: `djnikolic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/djordje-nikolic.jpg',
      type: 'partner',
      education: '	University of Belgrade Faculty of Law, 2000',
      barAdmissions: 'Serbian Bar Association',
      practiceAreas: 'Corporate and Commercial, M&A, Real Estate and Construction',
      trivia: `Đorđe Nikolić is founding partner at NKO Partners law firm. He has led number of complex
      privatization and M&A transactions since economic and political transition began in the former
      Yugoslavia in 2001. He is also recognized as the expert in real estate sector and has advised various
      multinational companies in developing of commercial, residential and industrial projects, including
      development of the largest shopping malls in the region.`
    },
    {
      name: 'Đuro Otašević',
      email: `djotasevic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/djuro-otasevic.jpg',
      type: 'partner',
      education: '	University of Belgrade Faculty of Law, 2004',
      barAdmissions: 'Montenegro Bar Association',
      practiceAreas: 'Corporate and Commercial, M&A, Competition / Antitrust, Mining',
      trivia: `Đuro Otašević is founding partner at NKO Partners law firm. He advises clients in all aspects of
      commercial law. He frequently deals with complex multi-jurisdictional joint ventures and acquisitions
      and advises clients on M&A, restructuring, competition, telecommunications and general corporate
      law. Đuro has unique and extensive experience advising high-profile clients in major transactions
      within mining industry.`
    },
    {
      name: 'Danica Milić',
      email: `dmilic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 515',
      imgUrl: '../../assets/img/employees/danica-milic.jpg',
      type: 'partner',
      education: 'University of Belgrade Faculty of Law, 2007',
      barAdmissions: 'Serbian Bar Association',
      practiceAreas: 'Dispute Resolution, Commercial, Employment',
      trivia: `Prior to joining the firm, Danica Milić was the Clerk for Principal Judge in the Commercial Court in
      Belgrade for 3 years. She heads the firm’s litigation department and has extensive experience in
      representing the clients in litigation and bankruptcy procedures. She is an expert in the employment
      law and advises number of clients on various day-to-day labor related matters, including labor
      disputes.`
    },
    {
      name: 'Petar Orlić',
      email: `porlic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 504',
      imgUrl: '../../assets/img/employees/petar-orlic.jpg',
      type: 'partner',
      education: '',
      barAdmissions: '',
      practiceAreas: '',
      trivia: `Petar is responsible for international business development at NKO. Having approximately 20 years’
      experience of working as a lawyer/partner in the city of London, Petar is the interface between NKO and its
      international clients and partners.`
    },
    {
      name: 'Branko Janković',
      email: `bzjankovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 521',
      imgUrl: '../../assets/img/employees/branko-z-jankovic.jpg',
      type: 'partner',
      education: 'University of Belgrade Faculty of Law, 2017, Master of Laws LL.M. in International Business Law, University of Belgrade Faculty of Law',
      barAdmissions: '',
      practiceAreas: 'Corporate, Employment, Real Estate and Construction',
      trivia: `Branko joined NKO Partners Law Office after graduating from the University of Belgrade, Faculty of Law. He is constantly involved in complex M&A
       transactions, especially in the pharmaceutical sector, providing legal support to clients through the whole process starting from comprehensive due
       diligence analysis up to transaction closing. Branko also regularly assists international and local clients on various corporate and commercial matters, 
       and provides legal advice on regulatory issues in the pharma industry.`
    },
    {
      name: 'Nikolija Damnjanović',
      email: `ndamnjanovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 516',
      imgUrl: '../../assets/img/employees/nikolija-damnjanovic.jpg',
      type: 'senior',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Corporate, Employment, Dispute Resolution',
      trivia: `Nikolija graduated from Belgrade University, Faculty of Law, with a master's degree in the field of commercial law. She joined NKO Partners after 
      previously working at the Commercial Court in Belgrade and a local commercial law firm.`
    },
    {
      name: 'Marija Čelebić',
      email: `mcelebic<span class='letter'>@</span>nko-law.com`,
      phone: '+382 (0)20 512 629',
      imgUrl: '../../assets/img/employees/marija-celebic.jpg',
      type: 'senior',
      education: 'University of Podgorica Faculty of Law, 2012',
      barAdmissions: 'Montenegro Bar Association',
      practiceAreas: 'Corporate and Commercial, Dispute Resolution, Employment',
      trivia: `Marija graduated at University in Podgorica, Faculty of Law in 2012 and is a member of the
      Montenegro Bar Association. Marija is advising national and international clients on a broad range of
      corporate and commercial issues.`
    },
    {
      name: 'Vojin Babović',
      email: `vbabovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 514',
      imgUrl: '../../assets/img/employees/vojin-babovic.jpg',
      type: 'senior',
      education: 'University of Belgrade Faculty of Law, 2018',
      barAdmissions: '',
      practiceAreas: 'Commercial, Dispute Resolution, Environmental',
      trivia: `Vojin advises clients on all aspects of corporate and employment law. He joined NKO Partners in
      2019 and has previously worked with local commercial law firm.`
    },
    {
      name: 'Luka Aleksić',
      email: `laleksic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/luka-aleksic.jpg',
      type: 'senior',
      education: 'University of Belgrade Faculty of Law, 2017, Master of Laws LL.M Law Theory, University of Belgrade, Faculty of Law',
      barAdmissions: '',
      practiceAreas: 'Corporate, Real Estate, General Commercial Law',
      trivia: `Luka regularly advises clients on various corporate/commercial and real estate matters.`
    },
    {
      name: 'Benjamin Graca',
      email: `bgraca<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 518',
      imgUrl: '../../assets/img/employees/benjamin-graca.jpg',
      type: 'senior',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Banking & Finance, Competition/Antitrust, Corporate',
      trivia: `Benjamin advises clients on finance, corporate and competition matters. Prior to joining NKO Partners, he 
      has worked for law firms in Serbia and Montenegro where he assisted clients in various aspects of 
      commercial, employment and business immigration law.`
    },
    {
      name: 'Goran Mihajlović',
      email: `gmihajlovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 522',
      imgUrl: '../../assets/img/employees/goran-mihajlovic.jpg',
      type: 'senior',
      barAdmissions: '',
      practiceAreas: 'Corporate, Tax, Employment, Dispute Resolution',
      trivia: `Goran has graduated from Belgrade University, Faculty of Law. After passing the Bar Exam, he joined NKO Partners, with previous experience 
      from one of the Big 4 audit firms and a local commercial law firm.`
    },
    {
      name: 'Stefan Savić',
      email: `ssavic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 525',
      imgUrl: '../../assets/img/employees/stefan-savic.jpg',
      type: 'senior',
      barAdmissions: '',
      practiceAreas: 'Competition/Antitrust, M&A and Corporate/Commercial',
      trivia: `Stefan joined the law firm NKO Partners at the end of 2024, after gaining extensive experience in leading national 
      and international law firms. He advises local and international clients in the areas of competition law and state aid, 
      with a particular focus on regulated industries such as telecommunications, pharmaceuticals, and healthcare. Additionally, 
      Stefan advises clients on matters of M&A, corporate, and commercial law, particularly in the preparation of all corporate status documentation and commercial contracts.`
    },
    {
      name: 'Ivana Šljukić',
      email: `isljukic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 503',
      imgUrl: '../../assets/img/employees/ivana-sljukic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Corporate, Employment, General Commercial Law',
      trivia: 'Ivana provides legal support to clients in general corporate/commercial and employment matters.'
    },
    {
      name: 'Kristina Stošić',
      email: `kstosic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 517',
      imgUrl: '../../assets/img/employees/kristina-stosic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Corporate, Employment, Dispute Resolution',
      trivia: `Kristina provides legal support to clients in general corporate/commercial and employment matters.`
    },
    {
      name: 'Valerija Mandić',
      email: ` vmandic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 522',
      imgUrl: '../../assets/img/employees/valerija-mandic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Corporate, General Commercial Law, Dispute Resolution',
      trivia: 'Valerija advises clients on all aspects of corporate and general commercial law.'
    },
    {
      name: 'Nikola Obradović',
      email: `nobradovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 524',
      imgUrl: '../../assets/img/employees/nikola-obradovic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Corporate, Real Estate, General Commercial Law',
      trivia: 'Nikola provides legal support to clients in Real Estate and general commercial matters.'
    },
    {
      name: 'Tara Butulija',
      email: `tbutulija<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 516',
      imgUrl: '../../assets/img/employees/tara-butulija.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Dispute Resolution, Corporate, Employment',
      trivia: 'Tara assists senior lawyers primarily in Dispute Resolution, Employment and general corporate matters.'
    },
    {
      name: 'Nataša Gledić',
      title: 'Office Manager',
      email: `ngledic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/natasa-gledic.jpg',
      type: 'office',
      education: '	Faculty of Organizational Sciences, Department of Management, 2009,   Belgrade Business School, Department of Marketing, 2006',
      barAdmissions: '',
      practiceAreas: '',
      // trivia: 'Responsible for the organization and coordination of office operations, financial matters, procedures and resources to facilitate organizational effectiveness and efficiency.'
    }
  ];

  private serbian = [
    {
      name: 'Đorđe Nikolić',
      email: `djnikolic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/djordje-nikolic.jpg',
      type: 'partner',
      education: 'Pravni fakultet Univerziteta u Beogradu, 2000',
      barAdmissions: 'Advokatska komora Srbije',
      practiceAreas: 'Korporativno pravo, M&A, Nepokretnosti',
      trivia: `Đorđe Nikolić je jedan od osnivača NKO Partners. Vodio je brojne značajne i komplikovane
      privatizacije i M&A transakcije posle društveno političkih promena u bivšoj Jugoslaviji 2001. Takođe
      se ističe kao stručnjak za sektor nepokretnosti koji je savetovao brojne multinacionalne kompanije u
      vezi sa projektima izgradnje komercijalnih, rezidencijalnih i industrijskih objekata, uključujući izgradnju
      najvećih šoping centara u regionu.`
    },
    {
      name: 'Đuro Otašević',
      email: `djotasevic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/djuro-otasevic.jpg',
      type: 'partner',
      education: 'Pravni fakultet Univerziteta u Beogradu, 2004',
      barAdmissions: 'Advokatska komora Crne Gore',
      practiceAreas: 'Korporativno pravo, M&A, Pravo konkurencije, Rudarstvo',
      trivia: `Đuro Otašević je jedan od osnivača NKO Partners. Savetuje klijente u svim oblastima privrednog
      prava. Klijentima često pruža pravnu podršku u vezi sa kompleksnim međunarodnim zajedničkim
      ulaganjima i preuzimanjima i savetuje klijente u vezi sa M&A, restrukturiranjem, pravom konkurencije,
      telekomunikacijama i opštim korporativnim pitanjima. Đuro poseduje dugogodišnje iskustvo na velikim
      transakcijama u oblasti rudarstva, u vezi sa čim je savetovao brojne klijente.`
    },
    {
      name: 'Danica Milić',
      email: `dmilic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 515',
      imgUrl: '../../assets/img/employees/danica-milic.jpg',
      type: 'partner',
      education: 'Pravni fakultet Univerziteta u Beogradu, 2007',
      barAdmissions: 'Advokatska komora Srbije',
      practiceAreas: 'Rešavanje sporova, Privredno pravo, Radno pravo',
      trivia: `Pre dolaska u kancelariju, Danica Milić je tri godine bila sudski saradnik u Privrednom sudu u
      Beogradu. Danica Milić vodi parnično odeljenje u kancelariji i ima značajno iskustvo u zastupanju
      klijenata u sudskim, arbitražnim i stečajnim postupcima. Takođe je stručnjak za oblast radnog prava i
      savetuje veliki broj klijenata u vezi sa radno pravnom materijom, uključujući i zastupanje u radnim
      sporovima.`
    },
    {
      name: 'Petar Orlić',
      email: `porlic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 504',
      imgUrl: '../../assets/img/employees/petar-orlic.jpg',
      type: 'partner',
      education: '',
      barAdmissions: '',
      practiceAreas: '',
      trivia: `Petar se bavi razvojem međunarodnog poslovanja u NKO. Petar, sa svojih oko 20 godina iskustva u radu
      kao advokat/partner u Londonu, dodatno povezuje NKO i međunarodne klijente kancelarije.`
    },
    {
      name: 'Branko Janković',
      email: `bzjankovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 521',
      imgUrl: '../../assets/img/employees/branko-z-jankovic.jpg',
      type: 'partner',
      education: 'Pravni fakultet Univerziteta u Beogradu, 2017<br>Master pravnih nauka LL.M,Međunarodno poslovno pravni modul. Pravni fakultet Univerziteta u Beogradu',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Radno pravo, Nepokretnosti',
      trivia: `Branko se pridružio advokatskoj kancelariji NKO Partners nakon diplomiranja na Pravnom fakultetu Univerziteta u Beogradu. Konstantno je angažovan u kompleksnim 
      M&A transakcijama, naročito u sektoru farmacije, pružajući pravnu podršku klijentima tokom celog procesa počev od sveobuhvatne analize poslovanja do zatvaranja transakcije. 
      Branko takođe svakodnevno savetuje međunarodne i lokalne klijente u različitim pitanjima iz oblasti korporativnog i privrednog prava, i pruža pravne savete o regulatornim 
      pitanjima u farmaceutskoj industriji.`
    },
    {
      name: 'Nikolija Damnjanović',
      email: `ndamnjanovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 516',
      imgUrl: '../../assets/img/employees/nikolija-damnjanovic.jpg',
      type: 'senior',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Radno pravo, Rešavanje sporova',
      trivia: `Nikolija je diplomirala na Pravnom fakultetu Univerziteta u Beogradu, sa master diplomom iz oblasti koroprativnog prava. Pridružila se advokatskoj kancelariji 
      NKO Partners, nakon što je prethodno radila u Privrednom sudu u Beogradu i u advokatskoj kancelariji koja se bavi privrednim pravom.`
    },
    {
      name: 'Marija Čelebić',
      email: `mcelebic<span class='letter'>@</span>nko-law.com`,
      phone: '+382 (0)20 512 629',
      imgUrl: '../../assets/img/employees/marija-celebic.jpg',
      type: 'senior',
      education: 'Pravni fakultet Univerziteta u Podgorici, 2012',
      barAdmissions: 'Advokatska komora Crne Gore',
      practiceAreas: 'Korporativno pravo, Pravo konkurencije, Rešavanje sporova, Radno pravo',
      trivia: `Marija je diplomirala na Pravnom fakultetu Univerziteta u Podgorici i član je Advokatske komore Crne
      Gore. Marija pruža savete domaćim i međunarodnim klijentima u oblasti privrednog prava i prava
      konkurencije.`
    },
    {
      name: 'Vojin Babović',
      email: `vbabovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 514',
      imgUrl: '../../assets/img/employees/vojin-babovic.jpg',
      type: 'senior',
      education: 'University of Belgrade Faculty of Law, 2018',
      barAdmissions: '',
      practiceAreas: 'Pravo konkurencije, Rešavanje sporova, Zaštita životne sredine',
      trivia: `Vojin savetuje klijente u svim aspektima korporativnog i radnog prava. On se pridružio advokatskoj
      kancelariji NKO Partners 2019. godine, a prethodno je radio u domaćoj advokatskoj kancelariji koja se
      bavi privrednim pravom.`
    },
    {
      name: 'Luka Aleksić',
      email: `laleksic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/luka-aleksic.jpg',
      type: 'senior',
      education: 'Pravni fakultet Univerziteta u Beogradu, 2017<br>Master pravnih nauka LL.M. Teorija prava, Pravni fakultet Univerziteta u Beogradu',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Nepokretnosti, Opšte privredno pravo.',
      trivia: `Luka svakodnevno savetuje klijente u vezi sa raznim pitanjima iz oblasti nepokretnosti i korporativnog
      prava.`
    },
    {
      name: 'Benjamin Graca',
      email: `bgraca<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 518',
      imgUrl: '../../assets/img/employees/benjamin-graca.jpg',
      type: 'senior',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Bankarsko pravo i finansije, Pravo konkurencije, Korporativno pravo',
      trivia: `Benjamin savetuje klijente u vezi sa pitanjima iz finansijskog prava, privrednog prava i prava konkurencije. 
      Pre nego što se pridružio advokatskoj kancelariji NKO Partners, radio je u advokatskim kancelarijama u Srbiji 
      i Crnoj Gori u kojima je savetovao klijente u vezi sa različitim pitanjima iz oblasti privrednog prava, radnog 
      prava i imigracionog prava.`
    },
    {
      name: 'Goran Mihajlović',
      email: `gmihajlovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 522',
      imgUrl: '../../assets/img/employees/goran-mihajlovic.jpg',
      type: 'senior',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Porezi, Radno pravo, Rešavanje sporova',
      trivia: `Goran je diplomirao na Pravnom fakultetu Univerziteta u Beogradu. Nakon polaganja pravosudnog ispita, pridružio se advokatskoj kancelariji NKO Partners sa 
      prethodnim iskustvom iz jedne od Big 4 kompanija za reviziju, kao i advokatske kancelarije koja se bavi privrednim pravom.`
    },
    {
      name: 'Stefan Savić',
      email: `ssavic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 525',
      imgUrl: '../../assets/img/employees/stefan-savic.jpg',
      type: 'senior',
      barAdmissions: '',
      practiceAreas: 'Pravo konkurencije/antimonopolsko pravo, spajanja, akvizicije i korporativno pravo',
      trivia: `Stefan se pridružio advokatskoj kancelariji NKO Partners krajem 2024. godine, nakon stečenog dugogodišnjeg iskustva u vodećim nacionalnim i međunarodnim advokatskim kancelarijama. 
      Stefan savetuje lokalne i međunarodne klijente u oblasti prava konkurencije i državne pomoći, sa posebnim fokusom na regulisane industrije telekomunikacija, farmacije i zdravstvene zaštite. 
      Pored toga, Stefan savetuje klijente u materiji M&A, korporativnog i privrednog prava, naročito u pogledu izrade sve statusne dokumentacije i komercijalnih ugovora. `
    },
    {
      name: 'Ivana Šljukić',
      email: `isljukic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 503',
      imgUrl: '../../assets/img/employees/ivana-sljukic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Radno pravo, Opšte privredno pravo',
      trivia: 'Ivana pruža pravnu pomoć klijentima u vezi sa raznim pitanjima iz oblasti korporativnog, privrednog i radnog prava.'
    },
    {
      name: 'Kristina Stošić',
      email: `kstosic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 517',
      imgUrl: '../../assets/img/employees/kristina-stosic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Radno pravo, Rešavanje sporova',
      trivia: `Kristina pruža pravnu pomoć klijentima u vezi sa raznim pitanjima iz oblasti korporativnog, privrednog i radnog prava.`
    },
    {
      name: 'Valerija Mandić',
      email: ` vmandic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 522',
      imgUrl: '../../assets/img/employees/valerija-mandic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Opšte privredno pravo, Rešavanje sporova',
      trivia: 'Valerija savetuje klijente u svim oblastima korporativnog i privrednog prava.'
    },
    {
      name: 'Nikola Obradović',
      email: `nobradovic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 524',
      imgUrl: '../../assets/img/employees/nikola-obradovic.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Korporativno pravo, Nepokretnosti, Opšte privredno pravo',
      trivia: 'Nikola savetuje klijente u vezi sa raznim pitanjima iz oblasti nepokretnosti i privrednog prava.'
    },
    {
      name: 'Tara Butulija',
      email: `tbutulija<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 516',
      imgUrl: '../../assets/img/employees/tara-butulija.jpg',
      type: 'associates',
      education: '',
      barAdmissions: '',
      practiceAreas: 'Rešavanje sporova, Privredno pravo, Radno pravo',
      trivia: 'Tara pruža podršku advokatima, posebno u rešavanju sporova, radnom pravu i opštim korporativnim pitanjima.'
    },
    {
      name: 'Nataša Gledić',
      title: 'Menadžer kancelarije',
      email: `ngledic<span class='letter'>@</span>nko-law.com`,
      phone: '+ 381 (0) 11 7150 500',
      imgUrl: '../../assets/img/employees/natasa-gledic.jpg',
      type: 'office',
      education: 'Fakultet organizacionih nauka, Odeljenje menadžmenta, 2009<br>Beogradska poslovna škola, Odeljenje marketinga, 2006',
      barAdmissions: '',
      practiceAreas: '',
    }
  ];

  getEmployees(language: string) {
    return this[language];
  }
}
